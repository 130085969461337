<template>
  <div class="px-2">
    <filter-slot
      :filter="[]"
      :total-rows="FiltersForSlot.totalRows"
      :paginate="FiltersForSlot.paginate"
      :start-page="FiltersForSlot.startPage"
      :to-page="FiltersForSlot.toPage"
      :filter-principal="FiltersForSlot.filterPrincipal"
      @reload="$refs['ownerTicketTable'].refresh()"
    >
      <template #buttons>
        <div class="ml-2">
          <b-button
            variant="warning"
            class="text-capitalize d-flex align-items-center"
            style="gap: 5px"
            @click="showModalRelationship = true"
          >
            <tabler-icon icon="PlusIcon" />
            Create a new relationship
          </b-button>
        </div>
      </template>
      <b-table
        ref="ownerTicketTable"
        slot="table"
        class="blue-scrollbar"
        :items="myProvider"
        :fields="Fields"
        :busy.sync="isBusy"
        primary-key="id"
        responsive="md"
        sticky-header="50vh"
        show-empty
        no-provider-filtering
        :per-page="FiltersForSlot.paginate.perPage"
        :current-page="FiltersForSlot.paginate.currentPage"
      >
        <template #cell(modules)="data">
          <div
            class="d-flex align-items-center justify-content-center"
            style="gap: 5px"
          >
            <div
              v-for="(module, index) in JSON.parse(data.item.modules)"
              :key="index"
            >
              <b-badge variant="light-primary" class="text-capitalize">
                {{ module.name }}
              </b-badge>
            </div>
          </div>
        </template>
        <template #cell(actions)="data">
          <div
            class="d-flex align-items-center justify-content-center"
            style="gap: 5px"
          >
            <feather-icon
              icon="EditIcon"
              class="cursor-pointer text-primary"
              size="18"
              @click="editRelation(data.item)"
            />
            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer text-danger"
              size="18"
              @click="deleteRelations(data.item)"
            />
          </div>
        </template>
      </b-table>
    </filter-slot>
    <RelationshipModal
      v-if="showModalRelationship"
      :item-edit="itemEdit"
      @close="closeModalRelationship"
    />
  </div>
</template>
<script>
import { ownersTableFields } from "@/views/paragon/views/settings/fields.js";
import RelationshipModal from "@/views/paragon/views/settings/modals/RelationshipModal.vue";
import ParagonSettingsService from "@/views/paragon/views/settings/service/paragon.service.js";
import { mapGetters } from "vuex";
export default {
  components: {
    RelationshipModal,
  },
  data() {
    return {
      Fields: ownersTableFields,
      FiltersForSlot: {
        totalRows: 0,
        paginate: {
          perPage: 10,
          currentPage: 1,
        },
        startPage: 1,
        toPage: 1,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Search by name...",
          model: "",
        },
      },
      isBusy: false,
      showModalRelationship: false,
      itemEdit: null,
    };
  },
  props: {
    tabSelected: String,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
  },
  methods: {
    async myProvider() {
      const params = {
        search: this.FiltersForSlot.filterPrincipal.model,
        currentPage: this.FiltersForSlot.paginate.currentPage,
        perPage: this.FiltersForSlot.paginate.perPage,
      };
      const data = await ParagonSettingsService.getModuleEngineers(params);
      this.FiltersForSlot.totalRows = data.total;
      this.FiltersForSlot.paginate.currentPage = data.current_page;
      this.FiltersForSlot.paginate.perPage = data.per_page;

      return data.data;
    },
    editRelation(item) {
      this.itemEdit = item;
      this.showModalRelationship = true;
    },
    closeModalRelationship(reload = false) {
      this.showModalRelationship = false;
      this.itemEdit = null;
      if (reload) {
        this.$refs["ownerTicketTable"].refresh();
      }
    },
    async deleteRelations(item) {
      const confirm = await this.showConfirmSwal(
        "Are you sure?",
        "You won't be able to revert this!"
      );
      if (!confirm.isConfirmed) return;
      try {
        this.addPreloader();
        await ParagonSettingsService.deleteModuleEngineers({
          engineer_id: item.user_id,
        });
        this.$refs["ownerTicketTable"].refresh();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>